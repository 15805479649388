<template>
	<div>
		<sb-tab @tabClick="tabClick" ref="tab" :list="tabList" type="border-card" class="toolsType" size="medium">
			<div class="toolsBox">
				<div class="title">{{$route.meta.name}}</div>
			</div>
			<router-view></router-view>
		</sb-tab>
	</div>
</template>

<script>
	import SbTab from '../../components/tab/tab.vue'
	export default {
		data() {
			return {
				tabList: [{
					name:'排名管理',
					id:'0',
					path:'/pageIndex/sellerTools/rank',
				},{
					name:'黑号查询',
					id:'1',
					path:'/pageIndex/sellerTools/blackQuery',
				},{
					name:'旺旺自动打标',
					id:'2',
					path:'/pageIndex/sellerTools/mark',
				},{
					name:'关键词卡首屏',
					id:'3',
					path:'/pageIndex/sellerTools/firstPage',
				},{	
					name:'猜你喜欢',
					id:'4',
					path:'/pageIndex/sellerTools/guessLike',
				},{	
					name:'指数转换',
					id:'5',
					path:'/pageIndex/sellerTools/transform',
				},{	
					name:'骗子库',
					id:'6',
					path:'/pageIndex/sellerTools/liar',
				},{	
					name:'淘客检测',
					id:'7',
					path:'/pageIndex/sellerTools/checkTaoKe',
				},]
			}
		},
		// beforeRouteEnter(news,old){
		// 	console.log(news,old)
		// 	this.tabList.forEach(item=>{
		// 		console.log(item)
		// 	})
		// },
		methods: {
			tabClick(e){
				// console.log(e)
				// console.log(news,olds)
				// console.log(this.tabList[news].path)
				this.$router.push({path:this.tabList[e.index].path})
			}
		},
		components: {
			SbTab,
		},
		watch: {
			// $route(newValue, oldValue) {
			// 	console.log(newValue, oldValue)
			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {
			var name = this.$route.meta.name
			this.tabList.forEach((item)=>{
				if(item.name == name){
					this.$refs.tab.set(item.id)
				}
			})
		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.toolsType{
		width: 980px;
	}
	.toolsBox{
		padding: 20px;
		.title{
			line-height: 50px;
			border-bottom: 1px solid #eee;
			padding-left: 10px;
		}
	}
</style>
